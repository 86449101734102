import React from 'react'
import './footer.css'
import Subscribe from "../../components/subscribe/subscribe"
import insta from '../../assets/insta.png'
import useAnalyticsEventTracker from '../../useAnalyticsEventTracker'
// import facebook from '../../assets/facebook.png'



const Footer = () => {
  const gaEventTracker = useAnalyticsEventTracker('Contact Us');
  return (
    <section id="footer">
        <div className='contact'>
        <h2>Drop us an email</h2>
        <a href="mailto:contact@thunderteauk.com?subject='Hello'" onClick={()=>gaEventTracker('email')}><h3>contact@thunderteauk.com</h3></a>
        <div className='footer-socials'>
        <a href="https://www.instagram.com/thunderteauk/" target="_blank" rel="noopener noreferrer" className='social-link'onClick={()=>gaEventTracker('Instagram')}><img src={insta} alt='Instagram' className='socialLogo'/></a>
        {/* <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" className='social-link'><img src={facebook} alt='Facebook' className='socialLogo'/></a> */}
        </div>
        </div>
        <div className='subscribe'>
        <h3>Get notified on our next event!</h3>
        <br/>
        <Subscribe/>
        </div>
    </section>
  )
}

export default Footer