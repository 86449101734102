import React, {useEffect } from 'react';
import Navbar from "./components/NavBar/navbar";
import Marquee from "./components/marquee/marquee";
import Landing from "./components/landing/landing";
import About from "./components/about/about";
import Menu from "./components/menu/menu";
import Events from "./components/events/events";
import Footer from "./components/footer/footer";
import { ToastContainer } from 'react-toastify';
import { Route, Routes} from "react-router-dom";
import ReactGA from 'react-ga4';
// import ReactGA from 'react-ga';

// pages
// import { QR } from "./pages/heating"
import { Mailinglist } from "./pages/Mailinglist"
import {EventTemplate} from "./pages/event"
import {DropImages} from "./assets"
import { DinnerDropInfo } from './assets';
import {LaunchImages} from './assets';
import {PotterImages} from './assets';
import {VeganuaryImages} from './assets'
import {LeytonImages} from './assets'
import {LowerImages} from './assets'
import {EastImages} from './assets'

//Analytics
const TRACKING_ID = 'G-51F7F8D7KG'
ReactGA.initialize(TRACKING_ID);
ReactGA.send("pageview");


function App() {
  // useEffect(() => {
  //   // ReactGA.pageview(window.location.pathname + window.location.search);
    
  // }, []);

  return (
      <Routes>
        <Route path="*" element={
          <>
            <Navbar />
            <Marquee />
            <ToastContainer />
            <Landing />
            <About />
            <Events />
            <Menu />
            <Footer />
          </>
        } />
        
        <Route path="/mailing-list" element={<Mailinglist />} />
        {/* <Route path="/heating-instructions" element={<QR />} /> */}
        <Route path="/Thunder-Tea-Launch" element={<EventTemplate title="Thunder Tea Launch" date="16.11.23" alt="Thunder Tea Launch - 16.11.23" img={LaunchImages} />} />
        <Route path="/Thunder-Tea-Dinner-Drop" element={<EventTemplate title="Nasi Lemak Dinner Drop" date="5.11.23" alt="Nasi Lemak Dinner Drop - 5.11.23" img={DropImages} info={DinnerDropInfo} />} />
        <Route path="/Thunder-TeaxPotter-Reid" element={<EventTemplate title="Thunder Tea x Potter & Reid" date="18.11.23" alt="Thunder Tea x Potter & Reid - 18.11.23" img={PotterImages} />} />
        <Route path="/Thunder-TeaxPotter-Reid-Veganuary" element={<EventTemplate title="Happy Veganuary" date="13.01.24" alt="Thunder Tea x Potter & Reid - Veganuary - 13.01.24" img={VeganuaryImages} />} />
        <Route path="/Thunder-TeaxHost-of-Leyton" element={<EventTemplate title="Thunder Tea x The Host Leyton" date="6.4.24" alt="Thunder Tea x The Host Leyton - 6.4.24" img={LeytonImages} />} />
        <Route path="/Thunder-TeaxLower" element={<EventTemplate title="Thunder Tea Pop-up @ Lower Wine Bar" date="10.5.24" alt="Thunder Tea Pop-up @ Lower Wine Bar - 10.5.24" img={LowerImages} />} />
        <Route path="/Thunder-TeaxEast&Most" element={<EventTemplate title="Thunder Tea Pop-up @ East & Most " date="16.5.24" alt="Thunder Tea Pop-up @ East & Most - 16.5.24" img={EastImages} />} />
      </Routes>
    );
  }



export default App;

