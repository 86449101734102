import React from 'react'
import './landing.css'
import backgroundVideo from "../../assets/Web_Video.mp4"

const Landing = () => {
  return (
    <section id="landing">
      <video autoPlay loop muted playsinline="true" disablePictureInPicture="true" preload="auto" id='tea'>
            <source src={backgroundVideo} type='video/mp4'/>
          </video>
        <div className='landingContainer'>
            <span className='hello'>Thunder Tea</span>
            <div className='introTxt'>Is a Singaporean and Malaysian Supper club in London </div>
        </div>
    </section>
  )
}

export default Landing 