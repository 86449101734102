import React from 'react'
import "./menu.css"
import {MdChevronLeft, MdChevronRight} from 'react-icons/md';
import { ScrollContainer } from 'react-indiana-drag-scroll';
import 'react-indiana-drag-scroll/dist/style.css'
import {
  Thunder,
  Nasi,
  Pandan,
  Shitaake,
  Okra,
  Keropok,
  Ondeh,
  Achar,
  Popiah,
  Sticky
} from '../../assets/'

const Menu = () => {
  const slideLeft = () => {
    var slider = document.getElementById('slider')
    slider.scrollBy({
      top: 0,
      left: -300,
      behavior: 'smooth'})
  }
  const slideRight = () => {
    var slider = document.getElementById('slider')
    slider.scrollBy({
      top: 0,
      left: 300,
      behavior: 'smooth'})
  } 

  

  return (
    <section id="menu">
      <span className='hello'>Menu</span>
      <div id='slider-container'>
        <MdChevronLeft className='slide-icon' onClick={slideLeft} size={40}/>
        <ScrollContainer id='slider'>
        {
            <ul className='menu-list'>
              <li>
                <div className='menu-container'>
                  <div className='menu-title'>Thunder Tea Rice</div>
                  <img src={Thunder} alt='Thunder Tea Rice' className='menu-img'/>
                  <div className='menu-body'>Our signature, and traditional family Hakka dish - Pounded savoury tea made with fresh herbs and nuts served over fragrant jasmine rice. 
Paired with: fried tofu with radishes, Chinese long beans, choy sum, roasted peanuts and Chinese cabbage. A favourite in our family. </div>
                </div>
              </li>
              <li>
                <div className='menu-container'>
                    <div className='menu-title'>Nasi Lemak</div>
                    <img src={Nasi} alt='Nasi Lemak' className='menu-img'/>
                    <div className='menu-body'>A classic meal adored by locals and a perfect mix of flavours: Creamy, rich Pandan and coconut infused butterfly pea basmati rice, fried vegan 'ikan-bilis', roasted nuts, omelette, cucumber slices and served with our signature Sambal.</div>
                  </div>
              </li>
              <li>
                <div className='menu-container'>
                  <div className='menu-title'>Pandan Cheesecake</div>
                  <img src={Pandan} alt='Pandan Cheesecake' className='menu-img'/>
                  <div className='menu-body'>Burnt caramelised creamy and smooth cheesecake infused with fresh Pandan leaves. </div>
                </div>
              </li>
              <li>
                <div className='menu-container'>
                  <div className='menu-title-s'>Deep Fried Shiitake Mushrooms</div>
                  <img src={Shitaake} alt='Deep Fried Shiitake Mushrooms' className='menu-img'/>
                  <div className='menu-body'>Crispy almond crusted mushrooms served with sambal mayo & pickled onions in coconut vinegar.</div>
                </div>
              </li>
              <li>
                <div className='menu-container'>
                  <div className='menu-title-s'>Grilled Okra & Hummus</div>
                  <img src={Okra} alt='Grilled Okra & Hummus' className='menu-img'/>
                  <div className='menu-body'>Soy-glazed grilled okra in miso & sesame hummus drizzled with kefir lime oi.</div>
                </div>
              </li>
              <li>
                <div className='menu-container'>
                  <div className='menu-title-s'>Keropok Bawang & Sambal Matah</div>
                  <img src={Keropok} alt='Keropok Bawang & Sambal Matah' className='menu-img'/>
                  <div className='menu-body'>Fried onion cracker served with a fiery raw chilli sauce made with onion, lemon grass, chilli paddi mixed with kaffir lime leaves.</div>
                </div>
              </li>
              <li>
                <div className='menu-container'>
                  <div className='menu-title'>Ondeh Ondeh</div>
                  <img src={Ondeh} alt='Ondeh Ondeh' className='menu-img'/>
                  <div className='menu-body'>Sweet and bite-sized glutinous Pandan rice balls, filled with gula melaka (molten palm sugar), sea salt and coated in freshly grated coconut.</div>
                </div>
              </li>
              <li>
                <div className='menu-container'>
                  <div className='menu-title'>Nonya Achar</div>
                  <img src={Achar} alt='Nonya Achar' className='menu-img'/>
                  <div className='menu-body'>Nonya spiced and pickled vegetables with a harmonious combination of sweet, sour and spicy taste with a rich nutty flavour. This unique dish is no stranger to those growing up around the Peranakan community in Singapore and Malaysia.</div>
                </div>
              </li>
              <li>
                <div className='menu-container'>
                  <div className='menu-title'>Popiah</div>
                  <img src={Popiah} alt='Popiah' className='menu-img'/>
                  <div className='menu-body'>Fresh spring roll filled with shredded turnip, carrots, beansprouts, radishes, Chinese lettuce, chopped peanuts wrapped in a soft, paper-like crepe and served with our signature Sambal. A staple in hawker centres in Singapore and Malaysia.</div>
                </div>
              </li>
              <li>
                <div className='menu-container'>
                  <div className='menu-title-s'>Tamarind Sticky Toffee Pudding</div>
                  <img src={Sticky} alt='Tamarind Sticky Toffee Pudding' className='menu-img'/>
                  <div className='menu-body'>The classic English dessert takes on a local twist. A sumptuously dark, fluffy all butter sponge made with dates, sweet tamarind sauce, and Pandan custard. The best combination.</div>
                </div>
              </li>
              {/* <li>
                <div className='menu-container'>
                  <div className='menu-title-s'>Nonya Kueh Pie Tee</div>
                  <img src={Sticky} alt='Nonya Kueh Pie Tee' className='menu-img'/>
                  <div className='menu-body'>A beloved Peranakan dish that combines a thin, crispy tart shell stuffed with a variety of vegetables in Nonya spices. As it resembles an upside-down hat, Kueh Pie Tee is known as ‘top hat’ in Malaysia.</div>
                </div>
              </li> */}
            </ul>
        }
    </ScrollContainer>
        <MdChevronRight className='slide-icon' onClick={slideRight} size={40}/>
      </div>
    </section>
  )
}

export default Menu