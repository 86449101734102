import React, {useState } from "react";
import jsonp from "jsonp";
import {toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useAnalyticsEventTracker from '../../useAnalyticsEventTracker'
import "./subscribe.css"

const SubscribeForm = () => {
    const [email, setEmail] = useState("")

    const handleSubmit = e => {
      e.preventDefault();
        const url = 'https://thunderteauk.us21.list-manage.com/subscribe/post-json?u=dad53646f6740a56635d313c0&amp;id=8f7d17fa00&amp;f_id=0088e1e6f0';
        jsonp(`${url}&EMAIL=${email}`, { param: 'c' }, (_, data) => {
            const { msg, result } = data 
            toast(msg, result);
        });
    }
  
    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target
      setEmail(value)
    }
    const gaEventTracker = useAnalyticsEventTracker('Subscribe');
  
    return (
      <form onSubmit={handleSubmit}>
        <input
          aria-label="email"
          name="email_address"
          placeholder="email"
          required
          type="email"
          onChange={handleEmailChange}
          value={email}
        />
        <button onClick={()=>gaEventTracker('mailing list')}><h3>SUBSCRIBE</h3></button>
      </form>
      
    )
  }

export default SubscribeForm