import React from 'react'
import "./about.css"
import {
  Family
} from '../../assets/'

const About = () => {
  return (
    <section id="about">
      <div className='mainContent'>
        <span className='hello'>Our Story</span>
        <div className='row'>
        <div className='columns'>
          <div class='left-column'>
          <span id='hello-s'>Our Story</span>
            <div className='about-txt'>
              I grew up helping at my parents’ hawker stall in Singapore where they served traditional noodle dishes for over 40 years. <br></br><br></br>
              I am of Hakka and Cantonese Chinese origin - My father was born in Singapore and my mother was from Ipoh, Malaysia. These recipes have been passed down through the family and were taught to me mainly by my father. My grandparents owned a vegetable farm in Lim Chu Kang in Singapore and Thunder Tea Rice has always been a classic in the family.
              My grandmother used to make them with the home-grown herbs and ingredients from their farm, and these recipes were taught by her parents and grandparents, with each generation adding its own unique twist. <br></br><br></br>
              Today, we added our own little touch to my grandmother’s recipes while honouring the roots from which it grew.
              When I first moved to the UK, I longed for the flavours of home. I couldn’t find Thunder Tea Rice anywhere so I started making it to remedy my homesickness. We started Thunder Tea UK as we wanted to share my family dishes and to share our deep love for Singapore and Malaysian cuisine with everyone.
            </div>
          </div>
          <div class='right-column'>
            <picture>
              <source media="(min-width: 1024px)" srcSet={Family} />
              {/* <source media="(max-width: 1080px)" srcSet={FamilyBG} /> */}
        
              <img src={Family} alt="Family" className='about-img' />
            </picture>
          </div>
        </div>
      </div>
      </div>

    </section>
  )
}

export default About



