export const Family = require('./about/Family.png');
export const FamilyBG = require('./about/Family-bg.png');

// MENU ITEMS
export const Thunder= require('./menu/THUNDERTEA_THUNDERTEA.jpg');
export const Nasi = require('./menu/THUNDERTEA_NASILEMAK.jpg');
export const Pandan = require('./menu/THUNDERTEA_PANDANCHEESECAKE.jpg');
export const Shitaake = require('./menu/Shitaake.jpg');
export const Okra = require('./menu/Okra.jpg');
export const Keropok = require('./menu/Keropok Bawang_Sambal_Matah.jpg');
export const Ondeh = require('./menu/THUNDERTEA_ONDEHONDEH.jpg');
export const Achar = require('./menu/THUNDERTEA_ACHAR.jpg');
export const Popiah = require('./menu/THUNDERTEA_POPIAH.jpg');
export const Sticky = require('./menu/THUNDERTEA_STICKYPUDDING.jpg');

//EVENTS
export const DinnerDrop = require('./events/Dinner-Drop/Dinner-Drop.jpg');
export const DinnerDrop1 = require('./events/Dinner-Drop/Dinner-Drop1.jpg');
export const DinnerDrop2 = require('./events/Dinner-Drop/Dinner-Drop2.jpg');
export const DropImages = [DinnerDrop, DinnerDrop1, DinnerDrop2]
export const DinnerDropInfo = "We kicked off Thunder Tea by serving 30+ portions of the iconic malysian dish around Walthamstow on November 5th. It was a thrilling experience delivering meals with fireworks going off in the background. Thank you to everyone that ordered!"

export const LaunchPoster = require('./events/Launch/Launch-poster.jpg');
export const Launch1 = require('./events/Launch/launch1.jpg');
export const Launch2 = require('./events/Launch/launch2.jpg');
export const Launch3 = require('./events/Launch/launch3.jpg');
export const Launch4 = require('./events/Launch/launch4.jpg');
export const LaunchImages = [LaunchPoster, Launch1, Launch2, Launch3, Launch4]

export const ThunderatPotterReid = require('./events/PotterReid/Poster02.png');
export const PotterReid1 = require('./events/PotterReid/potter-reid1.jpg');
export const PotterReid2 = require('./events/PotterReid/potter-reid2.jpg'); 
export const PotterReid3 = require('./events/PotterReid/potter-reid3.jpg');
export const PotterReid4 = require('./events/PotterReid/potter-reid4.jpg');
export const PotterImages = [ThunderatPotterReid, PotterReid1, PotterReid2, PotterReid3, PotterReid4]

export const Veganuary = require('./events/ThunderTeaUK-Jan2024.jpg');
export const VeganuaryVideo = require('./events/THUNDERTEA TikTOk.mp4');
export const VeganuaryImages = [Veganuary, VeganuaryVideo]

export const Leyton = require('./events/Leyton/Leyton.png');
export const Leyton1 = require('./events/Leyton/IMG_4864.JPG');
export const Leyton2 = require('./events/Leyton/IMG_4902.JPG');
export const Leyton3 = require('./events/Leyton/IMG_4906.JPG');
export const Leyton4 = require('./events/Leyton/IMG_4916.JPG');
export const LeytonImages = [Leyton, Leyton1, Leyton2, Leyton3, Leyton4]
    
export const LowerBlue = require('./events/Lower/Poster-Web-Blue.png')
export const LowerOrange = require('./events/Lower/Poster-Web-Orange.png')
export const Lower1= require('./events/Lower/IMG_3891.jpg')
export const Lower2= require('./events/Lower/IMG_3890.jpg')
export const LowerImages = [LowerBlue, Lower1, Lower2]

export const East = require('./events/East&Most/THUNDERTEA X EAST & MOST 2.JPG')
export const East1 = require('./events/East&Most/east1.jpg')
export const East2 = require('./events/East&Most/east2.jpg')
export const EastImages= [East, East1,East2]