import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter} from 'react-router-dom';
import ScrollToTop from "./components/ScrollToTop";
import { Helmet } from "react-helmet";
import  "./App.css"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
  <Helmet>
      <title>Thunder Tea - Singaporean Malaysian Supper Club</title>
      <meta
        name="description"
    content="Singaporean and Malaysian Supper club in London serving vegetarian and plant-based recipes with a bolt of flavour."
      />
      <meta
        name="keywords"
        content="singaporean, malaysian, supper club, london, vegan, vegetarian"
      />
    </Helmet>
  <ScrollToTop />
    <App />
  </BrowserRouter>
);

