import React from 'react'
import { Link } from 'react-router-dom'
import "./events.css"
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { ScrollContainer } from 'react-indiana-drag-scroll';
import 'react-indiana-drag-scroll/dist/style.css'

import { LaunchPoster, DinnerDrop, ThunderatPotterReid, Veganuary, Leyton, LowerBlue, East} from '../../assets/'


const Events = () => {
  const slideLeft = () => {
    var slider = document.getElementById('upcoming-slider')
    slider.scrollBy({
      top: 0,
      left: -300,
      behavior: 'smooth'
    })
  }
  const slideRight = () => {
    var slider = document.getElementById('upcoming-slider')
    slider.scrollBy({
      top: 0,
      left: 300,

      behavior: 'smooth'
    })
  }
  const slideLeftPrevious = () => {
    var slider = document.getElementById('previous-slider')
    slider.scrollBy({
      top: 0,
      left: -300,
      behavior: 'smooth'
    })
  }
  const slideRightPrevious = () => {
    var slider = document.getElementById('previous-slider')
    slider.scrollBy({
      top: 0,
      left: 300,
      behavior: 'smooth'
    })
  }

  return (
    <section id="events">
      <div className='mainContent'>
        <span className='hello'>Events</span>
        <div className='event-container'>
          <h2>Upcoming Events</h2>
          <div className='no-events'>No Upcoming Events</div>
          {/* <div id='slider-container'>
            <MdChevronLeft className='slide-icon' onClick={slideLeft} size={40} />
            <ScrollContainer id='upcoming-slider'>
              {
                <ul className='event-list'>
                  <a href='https://www.eventbrite.com/e/thundertea-x-lower-pop-up-11-may-sat-tickets-885592760787'>
                   <li>
                      <div class="overlay"></div>
                      <img src={LowerBlue} alt='Thunder Tea x Lower Wine Bar' className='event-img' />
                      <div className='overlay-txt small-txt'>Thunder Tea x Lower Wine Bar<br></br>11/05/2024</div>
                    </li>
                    </a>
                  <a href='https://www.eventbrite.com/e/thundertea-x-lower-pop-up-10-may-fri-tickets-885558127197'>
                    <li>
                      <div class="overlay"></div>
                      <img src={LowerOrange} alt='x Lower Wine Bar' className='event-img' />
                      <div className='overlay-txt small-txt'>Thunder Tea x Lower Wine Bar<br></br>10/05/2024</div>
                    </li>
                  </a>
                  
                </ul>
              }
            </ScrollContainer>
            <MdChevronRight className='slide-icon' onClick={slideRight} size={40} />
          </div> */}
        </div>
        <div className='event-container'>
          <h2>Previous Events</h2>
          <div id='slider-container'>
            <MdChevronLeft className='slide-icon' onClick={slideLeftPrevious} size={40} />
            <ScrollContainer id='previous-slider'>
              {
                <ul className='event-list'>
                  <Link to='/Thunder-TeaxEast&Most'>
                   <li>
                      <div class="overlay"></div>
                      <img src={East} alt='Thunder Tea x East & Most' className='event-img' />
                      <div className='overlay-txt small-txt'>Thunder Tea x x East & Most<br></br>16/05/2024</div>
                    </li>
                    </Link>
                   <Link to='/Thunder-TeaxLower'>
                   <li>
                      <div class="overlay"></div>
                      <img src={LowerBlue} alt='Thunder Tea x The Host Leyton' className='event-img' />
                      <div className='overlay-txt small-txt'>TThunder Tea x Lower Wine Bar<br></br>10/05/2024</div>
                    </li>
                    </Link>
                  <Link to='/Thunder-TeaxHost-of-Leyton'>
                   <li>
                      <div class="overlay"></div>
                      <img src={Leyton} alt='Thunder Tea x The Host Leyton' className='event-img' />
                      <div className='overlay-txt small-txt'>Thunder Tea x The Host Leyton<br></br>06/04/2024</div>
                    </li>
                    </Link>
                  <Link to='/Thunder-TeaxPotter-Reid-Veganuary'>
                    <li>
                      <div class="overlay"></div>
                      <img src={Veganuary} alt='Launch Poster' className='event-img' />
                      <div className='overlay-txt small-txt'>Thunder Tea x Happy Veganuary<br></br>13/01/2023</div>
                    </li>
                  </Link>
                   <Link to='/Thunder-TeaxPotter-Reid'>
                    <li>
                      <div class="overlay"></div>
                      <img src={ThunderatPotterReid} alt='Launch Poster' className='event-img' />
                      <div className='overlay-txt small-txt'>Thunder Tea x Potter & Reid<br></br>18/11/2023</div>
                    </li>
                  </Link>

                  <Link to='/Thunder-Tea-Launch'>
                    <li>
                      <img src={LaunchPoster} alt='Launch Poster' className='event-img' />
                      <div class="overlay"></div>
                      <div className='overlay-txt'><h3>Thunder Tea Launch<br></br>16/11/2023</h3></div>
                    </li>
                  </Link>
                  <Link to='/Thunder-Tea-Dinner-Drop'>
                    <li>
                      <img src={DinnerDrop} alt='Dinner Drop Poster' className='event-img' />
                      <div class="overlay"></div>
                      <div className='overlay-txt'><h3>Nasi Lemak Dinner Drop<br></br>5/11/2024</h3></div>
                    </li>
                  </Link>
                </ul>
              }
            </ScrollContainer>
            <MdChevronRight className='slide-icon' onClick={slideRightPrevious} size={40} />
          </div>

        </div>

      </div>
    </section>
  )
}

export default Events