import React from 'react'
import { ToastContainer } from 'react-toastify';
import './Malinglist.css';
import useAnalyticsEventTracker from '../useAnalyticsEventTracker'
import Subscribe from "../components/subscribe/subscribe"


export const Mailinglist = () => {
  const gaEventTracker = useAnalyticsEventTracker('Contact Us');
  return (
    <>
        <ToastContainer />
        <div className='mailing-list-container'>
            <div className='centered'>
                <Subscribe/>
            </div>
        </div>
    </>
  )
}

export default Mailinglist;