import React, { useState } from 'react';
import '../NavBar/navbar.css';
import logo from '../../assets/logo.png'
import {Link} from 'react-scroll';
import { HashLink } from 'react-router-hash-link';

const scrollWithOffset = (el, offset) => {
  const elementPosition = el.offsetTop - offset;
  window.scroll({
    top: elementPosition,
    left: 0,
    behavior: "smooth"
  });}

const NavbarExternal = () => {
  const [showMenu, setShowMenu] = useState(false)
  return (
    <>
    <nav className='navbar'>
        <div className='desktopMenu'>
            <HashLink activeClass='active' to='/#about' spy={true} smooth={true} scroll={el => scrollWithOffset(el, 80)} duration={500} className='desktopMenuListItem'>About</HashLink>
            <HashLink activeClass='active' to='/#menu' spy={true} smooth={true} scroll={el => scrollWithOffset(el, 80)} duration={500} className='desktopMenuListItem'>Menu</HashLink>
            <HashLink activeClass='activelogo' to='/#landing' spy={true} smooth={true} scroll={el => scrollWithOffset(el, 80)} duration={500} className='desktopMenuListItem'><img src={logo} alt='Logo' id='logo'/></HashLink>
            <HashLink activeClass='active' to='/#events' spy={true} smooth={true} scroll={el => scrollWithOffset(el, 80)} duration={500} className='desktopMenuListItem'>Events</HashLink>
            <Link activeClass='active' to='footer' spy={true} smooth={true} offset={-80} duration={500} className='desktopMenuListItem'>Contact</Link>
        </div>
        <div className='mobMenu'>
        <HashLink to='/#landing' spy={true} smooth={true} scroll={el => scrollWithOffset(el, 75)} duration={500}><img src={logo} alt='Logo' className='moblogo'/></HashLink>
          <div id="menuToggle">
            <input type="checkbox" onClick={()=>setShowMenu(!showMenu)}/>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div className='navMenu' style={{display: showMenu? 'flex':'none' }}>
            <HashLink to='/#about' spy={true} smooth={true} scroll={el => scrollWithOffset(el, 75)} duration={500} className='ListItem' onClick={()=>setShowMenu(false)}>About</HashLink>
            <HashLink to='/#menu' spy={true} smooth={true} scroll={el => scrollWithOffset(el, 75)} duration={500} className='ListItem' onClick={()=>setShowMenu(false)} >Menu</HashLink>
            <HashLink to='/#events' spy={true} smooth={true} scroll={el => scrollWithOffset(el, 75)} duration={500} className='ListItem' onClick={()=>setShowMenu(false)}>Events</HashLink>
            <Link to='footer' spy={true} smooth={true} offset={-75} duration={500} className='ListItem' onClick={()=>setShowMenu(false)}>Contact</Link>
        </div>
    </nav>
    </>
  )
}

export default NavbarExternal