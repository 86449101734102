import React, { useState } from 'react';
import './navbar.css';
import logo from '../../assets/logo.png'
import {Link} from 'react-scroll';



const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false)
  return (
    <>
    <nav className='navbar'>
        <div className='desktopMenu'>
            <Link activeClass='active' to='about' spy={true} smooth={true} offset={-80} duration={500} className='desktopMenuListItem'>About</Link>
            <Link activeClass='active' to='menu' spy={true} smooth={true} offset={-80} duration={500} className='desktopMenuListItem'>Menu</Link>
            <Link activeClass='activelogo' to='landing' spy={true} smooth={true} offset={-80} duration={500} className='desktopMenuListItem'><img src={logo} alt='Logo' id='logo'/></Link>
            <Link activeClass='active' to='events' spy={true} smooth={true} offset={-80} duration={500} className='desktopMenuListItem'>Events</Link>
            <Link activeClass='active' to='footer' spy={true} smooth={true} offset={-80} duration={500} className='desktopMenuListItem'>Contact</Link>
        </div>
        <div className='mobMenu'>
        <Link to='landing' spy={true} smooth={true} offset={-75} duration={500}><img src={logo} alt='Logo' className='moblogo'/></Link>
          <div id="menuToggle">
            <input type="checkbox" onClick={()=>setShowMenu(!showMenu)}/>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div className='navMenu' style={{display: showMenu? 'flex':'none' }}>
            <Link to='about' spy={true} smooth={true} offset={-80} duration={500} className='ListItem' onClick={()=>setShowMenu(false)}>About</Link>
            <Link to='menu' spy={true} smooth={true} offset={-80} duration={500} className='ListItem' onClick={()=>setShowMenu(false)} >Menu</Link>
            <Link to='events' spy={true} smooth={true} offset={-80} duration={500} className='ListItem' onClick={()=>setShowMenu(false)}>Events</Link>
            <Link to='footer' spy={true} smooth={true} offset={-80} duration={500} className='ListItem' onClick={()=>setShowMenu(false)}>Contact</Link>
        </div>
    </nav>
    </>
  )
}

export default Navbar