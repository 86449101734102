import React from 'react'
import Marquee from "react-fast-marquee";
import './marquee.css';

const Banner = () => {

  const Display = false
  if (!Display) return null;

  return (
    <a href="https://www.eventbrite.com/e/thundertea-x-lower-pop-up-11-may-sat-tickets-885592760787" target="_blank" rel="noopener noreferrer" >
    <Marquee className='banner' autoFill pauseOnHover>
  Reserve Your Table at Thunder Tea Pop-Up! - 11/05/24&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </Marquee>
    </a>
  )
}

export default Banner