import React from 'react'
import NavbarExternal from "../components/NavBarExternal/navbarExternal";
import Footer from "../components/footer/footer"
import Marquee from "../components/marquee/marquee";
import { ToastContainer } from 'react-toastify';
import './event.css';

export const EventTemplate = (props) => {
    const title = props.title;
    const date = props.date;
    const alt = props.alt
    const img = props.img
    const info = props.info

    //For Veganuary with only Video
    if (img.length < 3) {
        return (
            <>
                <NavbarExternal />
                <Marquee />
                <ToastContainer />
                <div className='event-page-container'>
                    <div className='row'>
                        <div className='columns'> 
                            <div class='left-column'>
                                <picture>
                                <img src={img[0]} alt={alt} className='event-page-img' />
                                </picture>
                                <div className='event-title'>
                                <h1>{title}</h1>
                                </div>
                                <h2>{date}</h2>
                                <div className='event-about-txt'>
                                    <p>{info}</p>
                                    <br></br>
                                </div>
                            </div>
                            <div class='right-column'>
                            <video className='event-page-img' controls >
                                <source src={img[1]} type="video/mp4"/>
                            </video>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }

    //For when there is only 2 images like dinner drop
    if (img.length < 5) {
        return (
            <>
                <NavbarExternal />
                <Marquee />
                <ToastContainer />
                <div className='event-page-container'>
                    <div className='row'>
                        <div className='columns'> 
                            <div class='left-column'>
                                <picture>
                                <img src={img[0]} alt={alt} className='event-page-img' />
                                </picture>
                                <div className='event-title'>
                                <h1>{title}</h1>
                                </div>
                                <h2>{date}</h2>
                                <div className='event-about-txt'>
                                    <p>{info}</p>
                                    <br></br>
                                </div>
                            </div>
                            <div class='right-column'>
                            <picture>
                                    <img src={img[1]} alt={alt} className='event-page-img' />
                                </picture>
                                <picture>
                                    <img src={img[2]} alt={alt} className='event-page-img' />
                                </picture>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
    //Normal page with 4 images
    return (
        <>
            <NavbarExternal />
            <Marquee />
            <ToastContainer />
            <div className='event-page-container'>
                <div className='row'>
                    <div className='columns'> 
                        <div class='left-column'>
                            <picture>
                            <img src={img[0]} alt={alt} className='event-page-img' />
                            </picture>
                            <div className='event-title'>
                            <h1>{title}</h1>
                            </div>
                            <h2>{date}</h2>
                            <div className='event-about-txt'>
                                <p>{info}</p>
                                <br></br>
                            </div>
                        </div>
                        <div class='right-column'>
                        <picture>
                                <img src={img[1]} alt={alt} className='event-page-img' />
                            </picture>
                            <picture>
                                <img src={img[2]} alt={alt} className='event-page-img' />
                            </picture>
                            <picture>
                                <img src={img[3]} alt={alt} className='event-page-img' />
                            </picture>
                            <picture>
                                <img src={img[4]} alt={alt} className='event-page-img' />
                            </picture>
                           
                        
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default EventTemplate;